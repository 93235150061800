import React from "react";
import { Helmet } from "react-helmet";

import { PageLayout } from "../../layouts";
import { Block, ArrowButton } from '../../components';

import "../../global/styles/page-styles/rules-page.scss";

const RazerRulesPage = () => {
    return (
        <>
            <Helmet>
                <title>Partie | Rules</title>
            </Helmet>
            <PageLayout className="rules-page">
                <Block className="rules-block-content" hero lazy>
                    <ArrowButton showBelow={250} />
                    <h1>Promotion Terms and Conditions</h1>
                    <p>This promotion is limited to new Partie Plus subscribers
                        using Razer Gold as their payment method.</p>
                    <p>One-time discount applies to first month subscription only,
                        after which a standard subscription price of $6.99 per month
                        will apply</p>
                    <p>Offer is valid from August 25th through November 25th, 2021.</p>
                    <p>All other terms and conditions apply.</p>
                </Block>
            </PageLayout>
        </>
    );
};

export default RazerRulesPage;